<template>
  <Layout>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <card-component>
        <div class="columns">
          <b-field label="Local" class="column is-one-third">
            <b-select
              placeholder="Elige una opción"
              expanded
              v-model="query.local_id"
              @input="filterDeliveryList"
            >
              <option
                v-for="local in localList"
                :key="local.id"
                :value="local.id"
              >{{ local.name }}</option>
            </b-select>
          </b-field>
        </div>
      </card-component>
      <card-component>
        <b-table
          :loading="isLoading"
          hoverable
          striped
          :data="results"
        >
          <b-table-column label="Nombre" field="product_variant_name" sortable v-slot="props">
            {{ props.row.product_variant_name }}
          </b-table-column>
          <b-table-column label="Asumido por Weeare" field="total" v-slot="props">
            <div>
              <span class="has-text-weight-medium">
                S/. {{ props.row.total.toFixed(2) }}
              </span>
              <p>Del {{ props.row.fecha_start }} al {{ props.row.fecha_end }}</p>
            </div>
          </b-table-column>
          <b-table-column width="170" label="Archivos" field="file" v-slot="props">
            <a
              class="has-text-weight-medium has-text-primary"
              @click="downloadBillingPdf(props.row.id)"
            >
              <b-icon icon="download" custom-size="default"></b-icon>
              Reporte PDF
            </a>
          </b-table-column>
          <b-table-column label="Estado" field="status" v-slot="props">
            <b-tag :type="getStatusClass(props.row.status_large)">
              {{ props.row.status_large }}
            </b-tag>
          </b-table-column>
          <b-table-column label="Factura" field="is_send_file" v-slot="props">
            <b-button
              :type="!props.row.is_send_file ? 'is-primary' : 'is-info'"
              @click="openModalBilling(props.row)"
            >
              {{ !props.row.is_send_file ? 'Facturar' : 'Actualizar' }}
            </b-button>
          </b-table-column>
          <loading-table slot="empty" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>
    <ModalBilling
      v-if="modaBilling"
      :idBilling="billing.id"
      :numInvoice="billing.num_invoice"
      @hidden="modaBilling = false"
      @reload="getBillingList"
    />
  </Layout>
</template>

<script>
import { mapState } from 'vuex'
import TitleBar from '@/components/TitleBar'
import Layout from '@/components/Layout'
import BillingService from '@/services/BillingService'
import CardComponent from '@/components/CardComponent.vue'
import ModalBilling from '@/components/Billing/ModalBilling'
import LoadingTable from '@/components/Base/LoadingTable'
export default {
  name: 'Error',
  components: {
    TitleBar,
    Layout,
    CardComponent,
    ModalBilling,
    LoadingTable
  },
  data () {
    return {
      billing: {},
      modaBilling: false,
      isLoading: false,
      locals: [],
      results: [],
      query: {
        local_id: this.$localStorage.get('local')
      }
    }
  },
  computed: {
    titleStack () {
      return ['Reportes', 'Facturas']
    },
    ...mapState(['localList'])
  },
  created () {
    this.getBillingList({ ...this.query })
  },
  methods: {
    errorMessage () {
      this.$buefy.toast.open({
        message: 'Ocurrió un error.',
        type: 'is-danger'
      })
    },
    saveLocal () {
      this.$store.dispatch('saveLocal', this.query.local_id)
    },
    openModalBilling (billing) {
      this.billing = billing
      this.modaBilling = true
    },
    getStatusClass (status) {
      switch (status) {
        case 'Pendiente de pago': return 'is-warning'
        case 'Pagado': return 'is-primary'
        default: return 'is-danger'
      }
    },
    async getBillingList (params) {
      try {
        this.isLoading = true
        const response = await BillingService.getBillingList(params)
        this.results = response.data
      } catch (error) {
        this.errorMessage()
      }
      this.isLoading = false
    },
    async downloadBillingPdf (id) {
      try {
        const response = await BillingService.downloadPdf({ billing_id: id })
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
        const fecha = new Date()
        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          `Reporte-factura-${fecha.getDate()}/${
            fecha.getMonth() + 1
          }/${fecha.getFullYear()}.pdf`
        )
        document.body.appendChild(fileLink)
        fileLink.click()
      } catch (error) {
        this.errorMessage()
      }
    },
    filterDeliveryList () {
      this.getBillingList({ ...this.query })
      this.saveLocal()
    }
  }
}
</script>
