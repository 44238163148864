<template>
  <b-modal v-model="modalBilling" :width="468">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <h3 class="modal-card-title is-size-4 has-text-weight-medium">
          Adjunta tus facturas
        </h3>
        <button
          type="button"
          class="delete"
          @click="modalBilling = false"
        />
      </header>
      <section class="modal-card-body">
        <small style="opacity:0.8;">
          Las facturas deberán tener un máximo de 7 días de antigüedad desde la emisión,
          de lo contrario no serán reconocidas para pago.
        </small>
        <hr />
        <b-field
          label="Número de factura"
          :type="{ 'is-danger': $v.num_invoice.$error }"
          :message="[
            {
              'Requerido. Ingresa el N° Factura':
                !$v.num_invoice.required && $v.num_invoice.$error
            }
          ]"
        >
          <b-input v-model="num_invoice" />
        </b-field>
        <b-field label="Factura en formato PDF">
          <file :file="dataPdf" @change="uploadFilePdf" formato="PDF"></file>
        </b-field>
        <b-field label="Factura en formato XML">
          <file :file="dataXml" @change="uploadFileXml" formato="XML"></file>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button
          label="Guardar"
          type="is-primary"
          :loading="isLoading"
          @click="saveBillingInvoice"
        />
        <b-button
          label="Cerrar"
          type="is-dark"
          @click="modalBilling = false"
        />
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import File from '@/components/Base/File'
import BillingService from '@/services/BillingService'
export default {
  props: {
    idBilling: Number,
    numInvoice: String
  },
  components: {
    File
  },
  data () {
    return {
      modalBilling: true,
      dataXml: '',
      dataPdf: '',
      formData: new FormData(),
      isLoading: false,
      dataXmlValidate: false,
      dataPdfValidate: false,
      num_invoice: this.numInvoice
    }
  },
  validations: {
    num_invoice: { required }
  },
  watch: {
    modalBilling () {
      this.$emit('hidden')
    }
  },
  methods: {
    uploadFilePdf (pdf) {
      if (pdf) {
        this.formData.append('pdf', pdf)
        this.dataPdfValidate = true
      }
    },
    uploadFileXml (xml) {
      if (xml) {
        this.formData.append('xml', xml)
        this.dataXmlValidate = true
      }
    },
    async saveBillingInvoice () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          if (!this.dataPdfValidate || !this.dataXmlValidate) { return }
          this.isLoading = true
          this.formData.append('num_invoice', this.num_invoice)
          await BillingService.putBillingInvoice(this.idBilling, this.formData)
          await this.$emit('reload')
          this.modalBilling = false
        } catch (error) {
          this.isLoading = false
          this.$buefy.toast.open({
            message: error.response.data.error,
            type: 'is-danger'
          })
        }
      }
    }
  }
}
</script>
