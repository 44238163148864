import axios from 'axios'
class BillingService {
  getBillingList (params = {}) {
    const response = axios.get('billing/', {
      params
    })
    return response
  }

  downloadPdf (params) {
    const response = axios.get('billing-pdf/', {
      responseType: 'blob',
      params
    })
    return response
  }

  putBillingInvoice (id, data) {
    const response = axios.put(`billing/${id}/invoice/`, data)
    return response
  }
}

const instance = new BillingService()
export default instance
